import React from 'react';
import { Link } from 'gatsby';
import MAHHLogo from 'src/images/mahh-logo-512.png';
import './Footer.scss';

const date = new Date();
const year = date.getFullYear();

function Footer() {
  return (
    <footer className='footer-container flex-wrap text-white border-top-white'>
      <div className='flex flex-col md:flex-row'>
        {/* Footer Logo */}
        <div className='flex footer-logo-container py-6 flex-40-percent justify-center md:justify-start'>
          <img
            src={MAHHLogo}
            className='header-logo'
            alt='mahh logo'
            width='32'
            height='32'
          />
          <p className='font-bold ml-16 text-2xl text-white'>APEPLUS STUDIO</p>
        </div>
        {/* List */}
        <div className='flex flex-60-percent flex-col md:flex-row'>
          {/* Footer contents 1st*/}
          <div className='sub-footer-container flex-center-center flex-17-percent py-3 lg:py-0'>
            <Link to='/career'>
              <div className='icon-container flex items-center py-3'>
                <span className='ml-4 text-xs font-bold'>CAREERS</span>
              </div>
            </Link>
            <Link to='/about'>
              <div className='icon-container flex items-center py-3'>
                <span className='ml-4 text-xs font-bold'>ABOUT US</span>
              </div>
            </Link>
          </div>
          {/* Footer contents 2nd */}
          <div className='sub-footer-container flex-center-center flex-17-percent py-3 lg:py-0'>
            <a
              href='https://www.instagram.com/apeplus.studio/'
              target='_blank'
              rel='noopener noreferrer'
            >
              <div className='icon-container flex items-center py-3'>
                <span className='ml-4 text-xs font-bold'>INSTAGRAM</span>
              </div>
            </a>
            <a
              href='https://www.youtube.com/@apeplusstudio5453'
              target='_blank'
              rel='noopener noreferrer'
            >
              <div className='icon-container flex items-center py-3'>
                <span className='ml-4 text-xs font-bold'>YOUTUBE</span>
              </div>
            </a>
            {/* <a
              href='https://www.linkedin.com/'
              target='_blank'
              rel='noopener noreferrer'
            > */}
            <div className='icon-container flex items-center py-3'>
              <span className='ml-4 text-xs font-bold'>LINKEDIN</span>
            </div>
            {/* </a> */}
          </div>
          {/* Footer contents 3rd */}
          <div className='sub-footer-container flex-center-center flex-17-percent py-3 lg:py-0 text-center md:text-left'>
            {/* <a href="" target="_blank" rel="noopener noreferrer"> */}
            <div className='icon-container py-3'>
              <span className='ml-4 text-xs font-bold'>
                Ruko Pisa Grande, D18
              </span>
            </div>
            <div className='ml-4 text-xxs'>
              Curug Sangereng, Kelapa Dua, Tangerang Regency, Banten 15810
            </div>
            {/* </a> */}
          </div>
        </div>
      </div>
      <div className='footer-copy-right text-center text-white text-xs lg:text-sm mt-16'>
        COPYRIGHT @{year} - APEPLUS. ALL RIGHTS RESERVED
      </div>
    </footer>
  );
}

export default Footer;
