import PropTypes from 'prop-types';
import React from 'react';
import 'typeface-inter';
import '../styles/style.css';
import Footer from 'src/components/shared/Footer.js';
import Header from 'src/components/Header.jsx';

const Layout = ({ children }) => {
  return (
    <>
      <Header />
      {children}
      <Footer />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  isHome: PropTypes.bool,
};

export default Layout;
